define("ember-svg-jar/inlined/icon-plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.5 15.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z\" stroke=\"#686B6E\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M9.777 5.942a1.269 1.269 0 00-1.037-.431 1.035 1.035 0 00-1.133 1.084c0 .628.342.939 1.207 1.294.865.356 1.14.817 1.14 1.378a1.187 1.187 0 01-1.244 1.225 1.844 1.844 0 01-1.347-.539M8.74 5.51V4.25m0 7.5v-1.26\" stroke=\"#666766\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "17",
      "height": "16",
      "viewBox": "0 0 17 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});