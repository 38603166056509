define("ember-svg-jar/inlined/icon-insights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.304 11.316v2.987H2.317v-2.987h1.987zm.21-1.006H2.107a.8.8 0 00-.797.797v3.405a.8.8 0 00.797.798h2.405a.8.8 0 00.798-.798v-3.405a.8.8 0 00-.798-.797zM9.32 7.317v6.987H7.335V7.317H9.32zm.21-1.007H7.124a.8.8 0 00-.797.798v7.405a.8.8 0 00.797.797H9.53a.8.8 0 00.797-.797V7.108a.8.8 0 00-.797-.798zM14.304 2.317v11.986h-1.987V2.317h1.987zm.21-1.007h-2.406a.8.8 0 00-.798.797v12.405a.8.8 0 00.798.798h2.405a.8.8 0 00.797-.798V2.107a.8.8 0 00-.797-.797z\" fill=\"#9D9D9D\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});